@import "sass-lib";


@media(min-width: 1px) and (max-width: 680px){
	h2{font-size: 18px;}
	h3{font-size: 16px; line-height: 22px;}
	h5{font-size: 12px; line-height: 18px;}

	.ui-datepicker{width: 15em;}
	.wrapper{padding: 0 20px;}
	.hline{padding-bottom: 25px;}
	.btn-oval{
		height: 32px; line-height: 32px; padding: 0 35px;
		&.btn-submit{font-size: 12px;}
		&.btn-yellow{padding: 0 12px; font-size: 12px;}
		&.btn-pink-large{font-size: 12px; padding: 0 15px;}
		&.btn-grey-large{font-size: 12px; padding: 0 15px;}
	}

	header.head-log .wrapper, .box-login .wrapper{padding: 0 30px;}

	/* row list styles 
    -----------------------------------------*/
    .row_list{
        width: auto;display: block;
        .col{
            &.col_xsml{width: 100%;}
            &.col_xsml75{width: 75%;}
            &.col_xsml7{width: 70%;}
            &.col_xsml65{width: 65%;}
            &.col_xsml6{width: 60%;}
            &.col_xsml55{width: 55%;}
            &.col_xsml5{width: 50%;}
            &.col_xsml45{width: 45%;}
            &.col_xsml4{width: 40%;}
            &.col_xsml37{width: 37%;}
            &.col_xsml35{width: 35%;}
            &.col_xsml33{width: 33.3333333333333%;}
            &.col_xsml3{width: 30%;}
            &.col_xsml25{width: 25%;}
            &.col_xsml2{width: 20%;}
            &.col_xsml16{width: 16.66666666666666%;}
            &.col_xsml1{width: 10%;}
        }
        &.center{
            text-align: center;
            .col{float: none; display: inline-block;}
        }
        &.listfull{
            margin-left: 0; margin-right: 0;
            .col{padding: 0;}
        }
        &.listp5{
            margin-left: 0; margin-right: 0;
            .col{padding: 0;}
        }
    }
    /* end row list styles 
    -----------------------------------------*/
	
	header{
		.header-top{
			font-size: 12px; padding: 10px 0; height: 40px; padding: 0; line-height: 40px;
			.head-left{
				.icwp{display: none;}
			}
			.head-right{
				span{display: none;}
			}
		}
		.logo{
			width: 100%; text-align: center;
			img{width: 100px;}
		}
		.nav-form-header{display: none;}
		&.head-log{
			.logo{
				img{width: 100px;}
			}
			.inner-head-log{padding: 15px 0;}
		}
	}


	.box-login{
		h1{font-size: 22px;}
		h2{font-size: 22px;}
		h5{font-size: 14px; line-height: 20px;}
		.text-left{padding: 45px 0 35px;}
		.title-log{
			padding-left: 35px;
			.icwp{
				&.ic_key{width: 20px; height: 20px;}
			}
		}
		.box-right-log{
			padding-left: 20px; padding-right: 20px;
			.box-form{
				font-size: 12px;
				input[type=text], input[type=password], input[type=email]{font-size: 12px;}
				label{
					padding-left: 20px;
					.icwp{
						&.ic_user{background-size: 100%; width: 15px;}
						&.ic_password{background-size: 100%; width: 15px;}
					}
				}
			}
		}
	}
	.box-simulation{
		padding-top: 40px; padding-bottom: 40px; margin-bottom: 50px;
	}
	.box-form{
		font-size: 12px;
		h2{margin-bottom: 30px;}
		label{margin-bottom: 10px;}
		bdo{font-size: 14px; line-height: 18px;}
		.row_list{
			.col{
				margin-bottom: 20px;
				&.marg-xs{margin-bottom: 20px;}
			}
		}
	}
	.box-disclaimer{
		padding: 20px 15px; font-size: 13px; line-height: 18px;
		p{line-height: 18px;}
	}
	.box-status-estimasi{
		font-size: 13px; line-height: 20px;
		p{line-height: 20px;}
		.left-estimasi{padding: 20px;}
		.right-estimasi{padding: 25px 20px;}
	}
	.box-btm-next{
		text-align: center;
		.left{max-width: 100%; width: 100%; padding-left: 10px; padding-right: 10px; margin-bottom: 15px;}
		.right{max-width: 100%; width: 100%;}
	}

	.box-question{
		.list-question{
			padding-left: 0;
			.box-number{
				position: relative;top: auto; left: auto; margin-bottom: 15px;
				&:after{content: ""; clear: both; display: block;}
			}
			.box-quiz{
				font-size: 13px;
				label{font-size: 13px;}
			}
		}
	}
	.slidetitle{
		.box-slidetitle{
			.list-slidetitle{font-size: 12px; min-width: 60px; padding-left: 20px; padding-right: 20px; padding-bottom: 20px; margin-bottom: 20px;}
		}
	}
	.popup{
		font-size: 13px; line-height: 20px;
		p{line-height: 20px;}
		.inner-popup{width: auto; left: 20px; right: 20px;}
		.wrap-popup{padding: 0 20px;}
	}
	.box-otp-code{width: 100%;}
	.box-btn-otp{
		.btn-oval{margin: 0 5px;}
	}
	.back_link{line-height: 30px;}


	header {
		.header-bottom {
			.logo {float: none;}
			&.adm {
				.nav_mn {
					float: none; display: block; text-align: center;
					a {margin: 0 10px; font-size: 16px;}
				}
			}
		}
	}

	.row_list {
		.col {
			> h3 {font-size: 14px;}
			&.ck_block {
				.box-radio-button {padding-top: 10px;}
			}
		}
	}
	.ck_check {font-size: 14px;}
	.data,.data_form {
		padding: 0 10px 15px;
		.col {
			label {font-size: 12px;}
			.txt {font-size: 12px;}
		}
	}
	.wrap-inner{
		&.full {
			.filter {
				.left,.right {
					float: none; width: 100%; display: block;
					input[type="text"] {width: 100%;}
				}
				.left {margin-bottom: 10px;}
			}
			.content_table {
				overflow: hidden;
				table {
					thead {display: none;}
					td {
						display: block; width: 100% !important; padding-left: 150px; position: relative; text-align: left;
						border-bottom: none;
						&:last-child {margin-bottom: 30px; border-bottom: 1px solid #d8d8d8;}
						&:before {
							content: attr(data-title); position: absolute; left: 15px; top: 15px; display: block; width: 150px;
						}
					}
				}
			}
		}
	}
	.pagging {margin: 20px 0 0;}
	.ck_check {font-size: 12px;}
}



@media(min-width: 1px) and (max-width: 479px){


.data {
	.col {
		label {float: none; display: inline-block; width: auto; padding-right: 10px; margin-bottom: 2px;}
		.txt {margin-left: 0; display: block;}
	}
}

.wrap-inner{
		&.full {
			.content_table {
				table {
					td {
						padding-left: 133px; padding-right: 5px;
						&:before {
							width: 130px; left: 10px;
						}
					}
				}
			}
		}
	}

}